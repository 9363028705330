import React, {useEffect} from "react"
import { graphql } from "gatsby"
import SectionBtn from "../components/section-btn"


import Layout from "../components/layout"

const IndexPage = (props) => {

    const {data} = props;

    const preLoadSectionImages = () => {
        const mobileImages = [...Array(9)].map((x, i) => "/mobile-app-sprites/frames/Scene." + (i + 31) + ".jpg");
        const mobileImagesMasks = [...Array(9)].map((x,i) => "/mobile-app-sprites/frames/masks/Scene." + (i + 31) + ".png");

        [
            ...mobileImages,
            ...mobileImagesMasks,
            data.iphone.childImageSharp.fluid.src,
            data.ipad.childImageSharp.fluid.src,
            data.mac.childImageSharp.fluid.src
        ].map(src => {
            const img = new Image();
            img.src = src;
            return img;
        })


    }

    useEffect(() => preLoadSectionImages(), []);

    return ( <Layout yoastData={props.data.wordpressPage.yoast || null}
                     seoTitle={props.data.wordpressPage.acf.seoTitle}
                    pageTitle={props.data.wordpressPage.title}>
            <div className="titles">
                <strong>Plan. Build. Launch.</strong>
                <h2>Codicate is a family owned development studio. <br />
                    We build unique products tailored to our partner’s needs.</h2>
            </div>

            <SectionBtn path="/mobile-native-applications" className="mobile-btn" label="Mobile Native Applications" pageBackground="toBlueBg"></SectionBtn>
            <SectionBtn path="/custom-made-systems" className="custom-btn"  label="Custom made systems" pageBackground="toRedBg"></SectionBtn>
            <SectionBtn path="/website-development" className="website-btn" isBlue={true} label="Website Development" pageBackground="toYellowBg"></SectionBtn>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
        fragment YoastData on wordpress__PAGE {
                    yoast {
                      title
                      focuskw
                      metadesc
                      metakeywords
                      opengraphTitle: opengraph_title
                      linkdex
                      metakeywords
                      canonical
                      opengraphTitle: opengraph_title
                      opengraphDescription: opengraph_description
                      opengraphImage: opengraph_image {
                        sourceURL: source_url
                      }
                      twitterTitle: twitter_title
                      twitterDescription: twitter_description
                      twitterImage: twitter_image {
                        sourceURL: source_url
                      }
                  }
                }
        query {
            wordpressPage(template: {eq: "front-page.php"}){
                title
                path
                ...YoastData
                acf {
                    seoTitle: seo_title
                }
            }
            ipad: file(relativePath: { eq: "website-development/ipad.png"}) {
            childImageSharp {
                fluid(maxWidth: 1300){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
            }
            iphone: file(relativePath: { eq: "website-development/iphone.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1300){
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            mac: file(relativePath: { eq: "website-development/mac.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1300){
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
 `
