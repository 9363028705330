import React from 'react';
import PropTypes from 'prop-types';
import TransitionButton from "../transition-button"
import './style.scss';

const SectionBtn = (props) => {

    const {className,label, path,isBlue,pageBackground} = props;

    return (
        <TransitionButton
            to={path ? path : '/'}
            className={`main-btn ${className ? className : ''}`}
            pageBackground={pageBackground}
            >
            <div>
                {/*<b></b><b></b><b></b><b></b><b></b>*/}
                <div className="text">
                    <strong>{label}</strong>
                    <span className={`btn ${isBlue ? 'blue' : ''}`}>See More</span>
                </div>
            </div>
        </TransitionButton>
        )
}

SectionBtn.propTypes = {
    label: PropTypes.string,
    path: PropTypes.string,
    isBlue: PropTypes.bool,
}

export default SectionBtn;